const DEAL_STATUS = {

  MODERATION: 'moderation',
  ACCEPTED: 'registered',
  CANCELED: 'canceled',
  WINNED: 'win',
  LOSED: 'loose',

}

const DEAL_STATUS_LIST = [

  { id: DEAL_STATUS.MODERATION, name: 'заявка на рассмотрении' },
  { id: DEAL_STATUS.ACCEPTED,   name: 'зарегистрирована' },
  { id: DEAL_STATUS.CANCELED,   name: 'не зарегистрирована' },
  { id: DEAL_STATUS.WINNED,     name: 'выиграна' },
  { id: DEAL_STATUS.LOSED,      name: 'проиграна' },

]

export {

  DEAL_STATUS,
  DEAL_STATUS_LIST
  
}