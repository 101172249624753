import { Fragment } from "react";
import useObjectState from "../../../../scripts/hooks/use.object.state";
import Textfield from "../../../components/inputs/textfield";
import ActionButtons from "../../inputs/action.buttons";

const FormOneField = ( props ) => {

  const { 

    initialData = { name: "" }, 
    handleConfirm, 
    handleCancel, 
    handleRemove = false,

    title = '',
    field = 'name',
    fieldTitle = 'Название:',

  } = props;

  const [ form, setFormField ] = useObjectState( initialData );

  return (

    <Fragment>

      <h2>{ initialData?.id ? 'Редактировать' : 'Добавить' } { title }</h2>
      
      <br/>

      <Textfield

        title = { fieldTitle }
        value = { form?.[ field ] }
        set = { n => setFormField( field, n ) }

      />

      <ActionButtons

        confirm = { () => handleConfirm( form ) }
        remove = { handleRemove }
        cancel = { handleCancel }

      />
      
    </Fragment>

  );

}

export default FormOneField;