import { Fragment, useEffect, useMemo } from "react";
import useObjectState from "../../../../../scripts/hooks/use.object.state";
import Textfield from "../../../inputs/textfield";
import ActionButtons from "../../../inputs/action.buttons";
import RenderJSON from "../../../ui/testing/render.json";
import SlotsContainer from "../../../containers/slots.container";
import useArrayOfObjects from "../../../../../scripts/hooks/use.array.of.objects";
import isNotEmpty from "../../../../../scripts/validators/is.not.empty";
import allObjectsIsHaveFilledKey from "../../../../../scripts/validators/all.objects.is.have.filled.key";
import MultislotSlot from "../../../cards/multislot.slot";

const MultislotForm = ({ initialData, handleConfirm, handleCancel, handleRemove = false  }) => {

  const [ form, setFormField ] = useObjectState( initialData );

  const initialSlotData = { slot_id: null }

  const slot = useArrayOfObjects( form?.multislot_slots, payload => setFormField( "multislot_slots", payload ), initialSlotData );
  
  const formIsValidated = useMemo(() => (
    
    isNotEmpty( form?.name )
    &&
    isNotEmpty( form?.multislot_slots )
    &&
    allObjectsIsHaveFilledKey( form?.multislot_slots, 'slot_id' )

  ), [ form?.name, slot._trigger ]);

  useEffect(() => {

    setFormField( "slotIds", isNotEmpty( form?.slots ) ? form.slots.map( s => s.slot_id ) : [] );

  }, [ slot._trigger ]);

  return (

    <Fragment>

      <h2>{ initialData?.id ? 'Редактировать' : 'Добавить' } мультислот</h2>
      
      <br/>

      <Textfield

        title = "Название мультислота:"
        value = { form.name }
        set = { n => setFormField("name", n ) }

      />

      <SlotsContainer add = { slot.add }>

        { !isNotEmpty( form?.multislot_slots ) ? null : form?.multislot_slots.map(( s, index ) => (

          <MultislotSlot

            key = { index }
            slotId = { s.slot_id }

            set = { ( field, value ) => slot.edit( index, field, value ) }
            remove = { () => slot.remove( index ) }

          />

        ))}

      </SlotsContainer>

      <RenderJSON data = { form?.slots } />

      <ActionButtons

        confirm = { () => handleConfirm( form ) }
        confirmDisabled = { !formIsValidated }

        remove = { handleRemove }
        cancel = { handleCancel }

      />

    </Fragment>

  );

}

export default MultislotForm;