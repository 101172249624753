import Carcas from "../../components/containers/carcas";
import backend from "../../../scripts/backend";
import API from "../../../constants/api";
import useGoBack from "../../../scripts/hooks/use.go.back";
import DistributorForm from "../../components/forms/deals/distributors.form";

const DistributorAdd = () => {

  const goBack = useGoBack();

  const handleAdd = ( form ) => {
    
    backend.add( API.distributor.add, form, goBack );
  
  }

  return (

    <Carcas>

      <DistributorForm 
      
        handleConfirm = { handleAdd }
        handleCancel  = { goBack } 
        
      />

    </Carcas>

  );

}

export default DistributorAdd;