import { useNavigate } from "react-router-dom";
import useGlobal from "../../../../store";
import Carcas from "../../../components/containers/carcas";
import DataList from "../../../components/ui/data.list";
import DataListControls from "../../../components/ui/data.list.controls";
import { useGet } from "../../../../scripts/backend/hooks";

const ServerGenerations = () => {

  const [ globalState, globalActions ] = useGlobal();
  const { changeStates } = globalActions;

  const navigate = useNavigate();

  const [ data, isLoaded ] = useGet('configurator/serverGeneration');

  function openEditForm( data ) {

    changeStates({ initialData: data });
    navigate(`/server-generations/${ data.id }`)

  }

  return (

    <Carcas>

      <DataListControls />

      { !isLoaded ? <p>Загружаю...</p> :

        <DataList

          source = { data }
          onRowClick = { openEditForm }
          widths = "1fr"
          names = {[ "Название" ]}
          values = {[ "name" ]}

        />

      }

    </Carcas>
    
  );

}

export default ServerGenerations;
