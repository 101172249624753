import DealForm from "../../components/forms/deals/deal.form";
import useGoBack from "../../../scripts/hooks/use.go.back";
import Carcas from "../../components/containers/carcas";
import { DEAL_STATUS } from "../../../constants/deals";
import backend from "../../../scripts/backend";
import { useParams } from "react-router-dom";
import API from "../../../constants/api";
import useGlobal from "../../../store";
import { useEffect } from "react";

const DealView = () => {

  const params = useParams();
  const { id } = params;
  const goBack = useGoBack();

  const [ globalState, globalActions ] = useGlobal();
  const { initialData } = globalState;
  const { changeStates } = globalActions;


  useEffect(() => {

    return () => { changeStates({ initialData: {} }) }

  }, []);

  const handleAccept = ({ status, partner_discount_date, partner_discount }) => {

    const params = {

      status,
      partner_discount_date,
      partner_discount

    }
    
    backend.patch( API.deal.save( id ), params, goBack );
  
  }

  const handleReject = () => {
    
    if ( !window.confirm('Точно хотите отклонить сделку?') ) return;
    
    const params = { status: DEAL_STATUS.CANCELED }

    backend.patch( API.deal.save( id ), params, goBack );
  
  }

  return (

    <Carcas>

      <DealForm 
      
        initialData   = { initialData } 

        handleConfirm = { handleAccept }
        handleRemove  = { handleReject }
        handleCancel  = { goBack }

        confirmText   = { "Сохранить изменения" }
        removeText    = { "Отклонить сделку" }
        
      />

    </Carcas>

  );

}

export default DealView;