import FormOneField from "../../form.one.field";

const ServerGenerationForm = ( p ) => (

  <FormOneField 
  
    title = 'поколение сервера'
    field = 'name'
    fieldTitle = 'Название поколения сервера:'
    initialData = { p?.initialData || { name: "" } }
    handleConfirm = { p.handleConfirm }
    handleCancel  = { p.handleCancel }
    handleRemove  = { p?.handleRemove || false }
    
  />

);

export default ServerGenerationForm;