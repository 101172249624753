const COMPONENT_TYPE = {

  CPU:    'cpu-type-id',
  RAM:    'ram-type-id',
  MEMORY: 'memory-type-id',

}

export {

  COMPONENT_TYPE,

}