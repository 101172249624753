const OTHER = { id: undefined, name: 'Другой' }

const DISK_SUBTYPES = [

  OTHER,
  { id: 'SAS', name: 'SAS' }, 
  { id: 'SATA', name: 'SATA' } 

];

export {

  OTHER,
  DISK_SUBTYPES,

}