function objectToGetParams( params ) {

  const paramsString = Object.keys( params ).map( key => {

    if ( Array.isArray( params[ key ] ) ) {

      return params[ key ].map(( val ) => (
        
        `${ encodeURIComponent( key ) }=${ encodeURIComponent( val.toString() )}`)
    
      ).join('&');

    }

    return `${ encodeURIComponent( key ) }=${ encodeURIComponent( params[ key ] ) }`;

  }).join('&');

  const nothingParams = paramsString === '&';
  return !nothingParams ? paramsString : '';

}

export default objectToGetParams;