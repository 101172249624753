import Carcas from "../../components/containers/carcas";
import DataList from "../../components/ui/data.list";
import useGlobal from "../../../store";
import { useNavigate } from "react-router-dom";
import { useGet } from "../../../scripts/backend/hooks";
import UrlField from "../../components/ui/data.list/data.list.fields/url.field";
import PriceField from "../../components/ui/data.list/data.list.fields/price.field";
import objectToGetParams from "../../../scripts/helpers/object.to.get.params";

const Deals = () => {

  const [ globalState, globalActions ] = useGlobal();
  const { changeStates } = globalActions;

  const url = new URL(window.location.href);
  const params = url.searchParams;

  // Преобразование всех параметров в объект
  const allGetParams = {};
  for (const [key, value] of params) {
    allGetParams[key] = value;
  }

  const navigate = useNavigate();

  const [ data, isLoaded ] = useGet(`deal?${ objectToGetParams( allGetParams ) }`);

  function openEditForm( data ) {

    changeStates({ initialData: data });
    navigate(`/deals/${ data.id }`)

  }

  return (

    <Carcas>

      { !isLoaded ? <p>Загружаю...</p> :

        <DataList

          source = { data }
          onRowClick = { openEditForm }

          widths = "13% 20% 11% 10% 18% 18% 18% 45%"

          names = {[

            "Номер",
            "Название",
            "Сумма",
            "Статус",
            "Партнёр",
            "Заказчик",
            "Дистрибьютор",
            "Конфигурация",

          ]}

          values = {[

            "deal_num",
            "title",
            "deal_sum",
            "status",
            "partner.email",
            "customer.company_name",
            "distributor.name",
            "configuration_link",

          ]}

          containers = {{ 
            
            //StatusField
            2: props => <PriceField { ...props } />,
            4: props => <UrlField prefix = "mailto:" { ...props } />,
            7: props => <UrlField prefix = "https://" { ...props } />,


          }}

        />

      }

    </Carcas>
    
  );

}

export default Deals;
