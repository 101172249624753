import Carcas from "../../../components/containers/carcas";
import backend from "../../../../scripts/backend";
import API from "../../../../constants/api";
import useGoBack from "../../../../scripts/hooks/use.go.back";
import CpuGenerationForm from "../../../components/forms/configurator/cpu.generation.form";

const CpuGenerationAdd = () => {

  const goBack = useGoBack();

  const handleAdd = ( form ) => {
    
    backend.add( API.configurator.component.cpu.generation.add, form, goBack );
  
  }

  return (

    <Carcas>

      <CpuGenerationForm
      
        handleConfirm = { handleAdd }
        handleCancel  = { goBack } 
        
      />

    </Carcas>

  );

}

export default CpuGenerationAdd;