import { DEAL_STATUS_LIST } from "../../../../../constants/deals";
import useObjectState from "../../../../../scripts/hooks/use.object.state";
import ActionButtons from "../../../inputs/action.buttons";
import Selectfield from "../../../inputs/selectfield";
import Textfield from "../../../inputs/textfield";
import "./deal.form.scss";

const DealForm = ( props ) => {

  const {

    initialData = {}, 
    handleConfirm = false, 
    handleCancel = false, 
    handleRemove = false, 
    confirmText = false, 
    cancelText = false, 
    removeText = false 

  } = props;

  const [ form, setFormField ] = useObjectState( initialData );

  const {

    title,
    deal_num,
    deal_sum,
    comment,
    status,
    configuration_link,
    competition_link,
    partner,
    customer,
    distributor,
    partner_discount,
    partner_discount_date,

  } = form;

  return (

    <div className = "deal_form">

      <div className = "grid">

        <div>

          <Textfield title = "Номер сделки" value = { deal_num } className = "events-none" />
          <Textfield title = "Название сделки" value = { title } className = "events-none" />
          <Textfield title = "Сумма сделки" value = { deal_sum } className = "events-none" />
          <Textfield title = "Комментарий" value = { comment } className = "events-none" />
          <Textfield title = "Ссылка на конфигурацию" value = { 'https://' + configuration_link } className = "events-none" />

          <br/>
          <hr/>
          <br/>

          <Selectfield

            title = "Статус сделки"
            list = { DEAL_STATUS_LIST }
            select = { n => setFormField("status", n.id ) }
            selected = { DEAL_STATUS_LIST.find( el => el.id === status )?.name }

          />


          <br/>
          <hr/>
              
        </div>

        <div>

          <Textfield title = "Компания заказчика" value = { customer?.company_name } className = "events-none" />
          <Textfield title = "ИНН компании заказчика" value = { customer?.inn } className = "events-none" />
          <Textfield title = "Фамилия заказчика" value = { customer?.last_name } className = "events-none" />
          <Textfield title = "Имя заказчика" value = { customer?.first_name } className = "events-none" />
          <Textfield title = "Ссылка на конкурс" value = { competition_link } className = "events-none" />

          <br/>
          <hr/>
          <br/>

          <Textfield 

            title = "Партнёрская скидка:" 
            value = { partner_discount } 
            placeholder = "Оставить пустым, если не надо выдавать скидку" 

          />


          <br/>
          <hr/>

        </div>

        <div>

          <Textfield title = "Телефон заказчика" value = { customer?.phone } className = "events-none" />
          <Textfield title = "E-mail заказчика" value = { customer?.email } className = "events-none" />
          <Textfield title = "Телефон партнёра" value = { partner?.phone } className = "events-none" />
          <Textfield title = "E-mail партнёра" value = { partner?.email } className = "events-none" />
          <Textfield title = "Дистрибьютор" value = { distributor?.name } className = "events-none" />

          <br/>
          <hr/>
          <br/>

          <Textfield 

            title = "Срок действия партнёрской скидки:" 
            value = { partner_discount_date } 
            placeholder = "Пусто, если нет скидки или ограничения времени" 

          />


          <br/>
          <hr/>

        </div>

      </div>

      <ActionButtons

        confirm = { handleConfirm && ( () => handleConfirm( form ) ) }
        confirmText = { confirmText }
        cancelText = { cancelText }
        removeText = { removeText }
        remove = { handleRemove }
        cancel = { handleCancel }

      />

    </div>

  );

}

export default DealForm;