import FormOneField from "../../form.one.field";

const ServerboxForm = ( p ) => (

  <FormOneField 
  
    title = 'корпус'
    field = 'name'
    fieldTitle = 'Название корпуса:'
    initialData = { p?.initialData || { name: "" } }
    handleConfirm = { p.handleConfirm }
    handleCancel  = { p.handleCancel }
    handleRemove  = { p?.handleRemove || false }
    
  />

);

export default ServerboxForm;