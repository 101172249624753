import FormOneField from "../../form.one.field";

const CpuGenerationForm = ( p ) => (

  <FormOneField 
  
    title = 'поколение процессора'
    field = 'name'
    fieldTitle = 'Название поколения процессора:'
    initialData = { p?.initialData || { name: "" } }
    handleConfirm = { p.handleConfirm }
    handleCancel  = { p.handleCancel }
    handleRemove  = { p?.handleRemove || false }
    
  />

);

export default CpuGenerationForm;